// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import {MEDIUM_FONT_FAMILY} from "../styles/typography";
import {GRAY, VERY_LIGHT_GREY, WHITE} from "../styles/colors";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../styles/sizes";

import CTAButton from "../components/CTAButton";
import Layout from "../container/Layout";

function ContactSuccess(): React.Node {
  const intl = useIntl();

  return (
    <Layout slug="contact-form-success">
      <div className={css(styles.container)}>
        <div className={css(styles.form)}>
          <h1 className={css(styles.title)}>
            {intl.formatMessage({id: "contact_form_success_title"})}
          </h1>
          <p className={css(styles.subtitle)}>
            {intl.formatMessage({id: "contact_form_success_subtitle"})}
          </p>
          <div className={css(styles.action)}>
            <CTAButton
              link="/"
              text={intl.formatMessage({id: "contact_form_success_CTA"})}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(80vh - 80px)",
    padding: `35px ${SIDE_MARGIN_MOBILE}`,
    alignItems: "flex-start",
    background: WHITE,

    [BREAKPOINTS.DESKTOP]: {
      alignItems: "center",
      padding: `10vh ${SIDE_MARGIN}`,
      background: VERY_LIGHT_GREY,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `10vh ${SIDE_MARGIN_WIDE}`,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    background: WHITE,
    borderRadius: "unset",
    boxShadow: "unset",
    padding: "0px",
    alignItems: "flex-start",

    [BREAKPOINTS.DESKTOP]: {
      alignItems: "center",
      borderRadius: "6px",
      padding: "50px 100px",
      boxShadow:
        "0 50px 100px -20px rgb(50 50 93 / 5%), 0 30px 60px -30px rgb(0 0 0 / 5%), 0 -18px 60px -10px rgb(0 0 0 / 1%)",
    },
  },
  title: {
    fontFamily: [MEDIUM_FONT_FAMILY],
    fontWeight: 900,
    margin: "25px 0px 0px 0px",
    fontSize: "24px",
    [BREAKPOINTS.DESKTOP]: {
      fontSize: "40px",
    },
  },
  subtitle: {
    color: GRAY,
  },
  action: {
    padding: "35px 0px 10px 0px",
  },
});

export default ContactSuccess;
